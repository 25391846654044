const industries = [
  {
    key: "Aeroespacial-y-Defensa",
    name: "Aeroespacial y Defensa"
  },
  {
    key: "Agricultura-y-Alimentacion",
    name: "Agricultura y Alimentación"
  },
  {
    key: "Alta-Tecnologia",
    name: "Alta Tecnología"
  },
  {
    key: "Automotriz",
    name: "Automotriz"
  },
  {
    key: "Bancos-y-Valores",
    name: "Bancos y Valores"
  },
  {
    key: "Call-Center-y-Atencion-al-Cliente",
    name: "Call Center y Atención al Cliente"
  },
  {
    key: "Casas-Inteligentes-y-Domotica",
    name: "Casas Inteligentes y Domótica"
  },
  {
    key: "Ciberseguridad",
    name: "Ciberseguridad"
  },
  {
    key: "Capacitacion-para-Empresas-y-Organizaciones",
    name: "Capacitación para Empresas y Organizaciones"
  },
  {
    key: "Construccion-e-Inmobiliario",
    name: "Construcción e Inmobiliario"
  },
  {
    key: "Consultoria-y-Servicios-Profesionales",
    name: "Consultoría y Servicios Profesionales"
  },
  {
    key: "Contabilidad-y-Finanzas",
    name: "Contabilidad y Finanzas"
  },
  {
    key: "Deportes-y-Bienestar",
    name: "Deportes y Bienestar"
  },
  {
    key: "Distribucion-y-Mayorista",
    name: "Distribución y Mayorista"
  },
  {
    key: "Educacion-y-Formacion",
    name: "ducación y Formación"
  },
  {
    key: "Energia-Quimicos-y-Utilidades",
    name: "Energía, Químicos y Utilidades"
  },
  {
    key: "Energias-Renovables-y-Medio-Ambiente",
    name: "Energías Renovables y Medio Ambiente"
  },
  {
    key: "Fabricacion-e-Industria",
    name: "Fabricación e Industria"
  },
  {
    key: "Gobierno-y-Administracion-Publica",
    name: "Gobierno y Administración Pública"
  },
  {
    key: "Hotel-Viajes-y-Turismo",
    name: "Hotel, Viajes y Turismo"
  },
  {
    key: "Informatica-Software-y-Tecnologia-de-la-Informacion-(IT)",
    name: "Informática, Software y Tecnología de la Información (IT)"
  },
  {
    key: "Investigacion-y-Desarrollo-(I+D)",
    name: "nvestigación y Desarrollo (I+D)"
  },
  {
    key: "Legal-y-Cumplimiento",
    name: "Legal y Cumplimiento"
  },
  {
    key: "Logistica-y-Cadena-de-Suministro",
    name: "ogística y Cadena de Suministro"
  },
  {
    key: "Marketing-Publicidad-y-RRPP",
    name: "Marketing, Publicidad y RRPP"
  },
  {
    key: "Medicina-Farmacia-Biotecnologia-y-Salud",
    name: "Medicina, Farmacia, Biotecnología y Salud"
  },
  {
    key: "Medios-de-Comunicacion-y-Entretenimiento",
    name: "Medios de Comunicación y Entretenimiento"
  },
  {
    key: "Mineria-y-Recursos-Naturales",
    name: "Minería y Recursos Naturales"
  },
  {
    key: "Organizaciones-Sin-Fines-de-Lucro",
    name: "Organizaciones Sin Fines de Lucro"
  },
  {
    key: "Outsourcing-y-Soporte-Técnico",
    name: "Outsourcing y Soporte Técnico"
  },
  {
    key: "Retail-y-Comercio",
    name: "Retail y Comercio"
  },
  {
    key: "Seguros-y-Servicios-Financieros",
    name: "Seguros y Servicios Financieros"
  },
  {
    key: "Telecomunicaciones-y-Redes",
    name: "Telecomunicaciones y Redes"
  },
  {
    key: "Transporte-y-Movilidad",
    name: "Transporte y Movilidad"
  },
  {
    key: "Engineering",
    name: "Ingeniería"
  },
  {
    key: "Recruitment-and-Human-Resources",
    name: "Reclutamiento y recursos humanos"
  },
  {
    key: "Security",
    name: "Seguridad"
  },
  {
    key: "Trade-Association",
    name: "Asociación gremial"
  },
  {
    key: "Machinery",
    name: "maquinaria"
  },
  {
    key: "Venture-Capital",
    name: "Venture Capital"
  },
  {
    key: "Consciousness-and-Personal-Development",
    name: "Consciencia y desarrollo personal"
  },
  {
    key: "Social-and-Environmental-Impact",
    name: "Impacto Social y Ambiental"
  },
  {
    key: "Beauty-and-Aesthetics",
    name: "Belleza y estética"
  }
];

const countries = [
  {
    id: 1,
    name: "Argentina"
  },
  {
    id: 2,
    name: "Bolivia"
  },
  {
    id: 3,
    name: "Brasil"
  },
  {
    id: 4,
    name: "Chile"
  },
  {
    id: 5,
    name: "Colombia"
  },
  {
    id: 6,
    name: "Costa Rica"
  },
  {
    id: 7,
    name: "Cuba"
  },
  {
    id: 8,
    name: "Ecuador"
  },
  {
    id: 9,
    name: "El Salvador"
  },
  {
    id: 10,
    name: "España"
  },
  {
    id: 22,
    name: "Estados Unidos"
  },
  {
    id: 11,
    name: "Guatemala"
  },
  {
    id: 12,
    name: "Honduras"
  },
  {
    id: 13,
    name: "México"
  },
  {
    id: 14,
    name: "Nicaragua"
  },
  {
    id: 15,
    name: "Panamá"
  },
  {
    id: 16,
    name: "Paraguay"
  },
  {
    id: 17,
    name: "Perú"
  },
  {
    id: 18,
    name: "Puerto Rico"
  },
  {
    id: 19,
    name: "República Dominicana"
  },
  {
    id: 20,
    name: "Uruguay"
  },
  {
    id: 21,
    name: "Venezuela"
  }
];

const quantityOptions = [
  { value: "1-10", show: "1-10" },
  { value: "11-50", show: "11-50" },
  { value: "51-250", show: "51-250" },
  { value: "251-1.000", show: "251-1.000" },
  { value: "1.001-5.000", show: "1.001-5.000" },
  { value: "Más-de-5.000", show: "Más de 5.000" }
];

const userHierarchyLevels = [
  "Administrative",
  "ConsultantOrAdvisor",
  "IndependentContractor",
  "Director",
  "ExecutiveOrCSuite",
  "Manager",
  "Researcher",
  "Operational",
  "ProfessionalOrTechnician",
  "Supervisor",
  "Volunteer"
];

const departmentAreas = [
  "AdministrationAndFinance",
  "ArtAndDesign",
  "PurchasingAndProcurement",
  "Consulting",
  "QualityControl",
  "ManagementControl",
  "ProductDevelopmentEngineering",
  "DesignImagingAndMediaProduction",
  "EducationAndTraining",
  "ProjectManagement",
  "ResearchAndDevelopment",
  "LegalAndCompliance",
  "LogisticsAndSupplyChain",
  "MarketingAndCommunications",
  "Operations",
  "JournalismReportingAndEditorial",
  "HumanResources",
  "PublicRelations",
  "HealthAndSafety",
  "CustomerService",
  "SustainabilityAndCorporateSocialResponsibility",
  "InformationTechnology",
  "Sales",
  "General"
];

const organizationsType = [
  "tradeAssociation",
  "mutualAssociation",
  "consortiumOrAlliance",
  "cooperative",
  "individualEntrepreneurOrSoloEntrepreneur",
  "familyOwnedOrProprietorManagedPrivateCompany",
  "professionallyManagedPrivateCompany",
  "publicCompany",
  "sportsEntityOrClub",
  "governmentalOrganization",
  "nonProfitOrganizationOrNGO",
  "startup"
];

export {
  userHierarchyLevels,
  departmentAreas,
  quantityOptions,
  countries,
  industries,
  organizationsType
};
