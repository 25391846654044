
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "_app_organization_procede",

  setup() {
    const store = useStore();
    const { t, te } = useI18n();

    const organizationToRejected = computed(() => store.getters.getOrganizationToRejected);

    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const confirmRemoving = async () => {
      const closeButton: HTMLElement | null = document.getElementById(
        "removing_organization_procede"
      );

      await store.dispatch(Actions.ACCEPT_ORGANIZATION_INVITATION, {
        organizationId: organizationToRejected.value,
        choose: 'rejected'
      });

      await store.dispatch(Actions.LOAD_ALL_ORGANIZATIONS);


      if (closeButton) closeButton.click();

    };

    return {
      translate,
      confirmRemoving
    };
  }
});
