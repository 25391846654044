<template>
  <!--begin::Dashboard-->
  <div
    v-if="!authenticatedUser.validated_email"
    class="notice d-flex bg-light-warning rounded p-6 card-body ms-3 me-8"
  >
    <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
      <inline-svg src="media/icons/duotone/Code/Warning-1-circle.svg" />
    </span>
    <!--begin::Wrapper-->
    <div class="d-flex flex-stack flex-grow-1">
      <!--begin::Content-->
      <div class="fw-bold">
        <h4 class="text-gray-800 fw-bolder">
          {{ translate("important") }}
        </h4>

        <div class="fs-6 text-gray-600">
          {{ first() }}
        </div>
        <div class="fs-6 text-gray-600">
          {{ second()[0] }}
          <a href="#" @click.prevent="resendEmail">{{ translate("here") }}</a>
          {{ second()[1] }}
        </div>
        <div class="fs-6 text-gray-600">
          {{ third()
          }}<a href="#" @click.prevent="chanceRoute">{{ translate("here") }}</a>
        </div>
      </div>
      <!--end::Content-->
    </div>
    <!--end::Wrapper-->
  </div>
  <div class="row gy-5 g-xl-8" v-if="this.authenticatedUser.validated_email">
    <OrganizationList :organizations="organizations" />
  </div>

  <div class="row gy-5 g-xl-8" v-if="!organizations">
    <div class="sinOrganizacion">
      <p>{{ translate("withOutOrganization") }}</p>
      <button
        type="button"
        class="btn btn-sm btn-success ms-4"
        data-bs-toggle="modal"
        data-bs-target="#_app_organization_new_form_modal"
        id="kt_toolbar_primary_button"
        @click.prevent="createOrganization()"
      >
        <i class="bi bi-plus" />
        {{ translate("createOrganization") }}
      </button>
    </div>
  </div>
  <!--end::Dashboard-->

  <!-- begin:: Organization -->
  <OrganizationNewFormModal @onSave="saveOrganization" />
  <!-- end:: Organization Modal -->
</template>

<style>
.sinOrganizacion {
  display: table;
  margin: 10% auto;
  background: #fff;
  max-width: 500px;
  text-align: center;
  padding: 40px;
  border-radius: 15px;
}

.sinOrganizacion p {
  font-size: 16px;
  margin-bottom: 25px;
}
</style>

<script>
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import useEmitter from "@/core/plugins/emitter.js";
import JwtService from "@/core/services/JwtService";

import OrganizationList from "@/components/app/Organizations/OrganizationList.vue";
import OrganizationNewFormModal from "@/components/app/Organizations/OrganizationNewFormModal.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "commander",
  components: {
    OrganizationList,
    OrganizationNewFormModal
  },
  mounted() {
    if (!this.authenticatedUser || !this.authenticatedUser.id) {
      console.info("[User is not authenticated]");
      this.$notify({
        title: this.translate("userNotAuthenticatedTitle"),
        message: this.translate("userNotAuthenticatedMessage"),
        type: "danger"
      });
      this.$router.push("/sign-in");
      return;
    }
    // Load authenticated user organizations
    this.loadOrganizations();
    this.emitter.on("open-organization-modal", this.openOrganizationModal);
    this.emitter.on("open-organization-new-modal", this.openOrganizationModal2);
    this.emitter.on("load-organizations", this.loadOrganizations);
    this.emitter.on("close-organization-modal", this.closeOrganizationModal);
  },
  unmounted() {
    this.emitter.off("open-organization-modal");
    this.emitter.off("close-organization-modal");
    this.emitter.off("load-organizations");
    this.emitter.off("open-organization-new-modal");
  },
  setup() {
    const emitter = useEmitter();
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      setCurrentPageTitle(translate("myOrganizations"));
    });

    const organizations = computed(() => store.getters.getOrganizations);

    const resendEmail = () => {
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/users/resend-email`;
      const currentToken = JwtService.getToken();

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        }
      })
        .then(res => res.json())
        .then(() => {
          Swal.fire({
            text: translate("forwardedMail"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary"
            }
          });
        });
    };

    const chanceRoute = () => {
      router.push({ path: "/crafted/account/user-account" });
    };

    const first = () => {
      return translate("emailWarning.first");
    };

    const second = () => {
      const msg = translate("emailWarning.second").split("click-here");
      return msg;
    };

    const third = () => {
      return translate("emailWarning.thirds").replace(
        "userEmail",
        store.getters.currentUser.email
      );
    };

    return {
      emitter,
      translate,
      first,
      second,
      third,
      resendEmail,
      chanceRoute,
      organizations
    };
  },

  data() {
    return {
      isWaiting: false,
      organization: null
    };
  },
  computed: {
    authenticatedUser() {
      return this.$store.getters.currentUser;
    }
  },

  watch: {
    authenticatedUser(authenticatedUserValue, oldValue) {
      if (authenticatedUserValue.id && !oldValue.id) {
        this.loadOrganizations();
      }
    }
  },

  methods: {
    loadOrganizations() {
      this.isWaiting = true;
      const userId =
        this.authenticatedUser && this.authenticatedUser.id
          ? this.authenticatedUser.id
          : null;

      if (!userId) {
        this.$router.push("/sign-in");
        return;
      }

      this.$store.dispatch(Actions.LOAD_ALL_ORGANIZATIONS, userId);
    },

    openOrganizationModal(organization) {
      setTimeout(() => {
        if (organization) {
          this.organization = organization;
        } else {
          this.organization = null;
        }
      }, 111);
    },

    openOrganizationModal2() {
      this.organization = null;
    },

    closeOrganizationModal() {
      setTimeout(() => {
        this.organization = null;
      }, 111);
    },

    saveOrganization(organization) {
      const action = organization.id ? "PUT" : "POST";
      const url = `https://${process.env.VUE_APP_BACKEND_PATH}.com/${process.env.VUE_APP_BACKEND_PORT}/organizations`;
      const currentToken = JwtService.getToken();

      const organizationToSave = {
        ...organization,
        owner: this.authenticatedUser.id,
        motive: organization.motive,
        shareOkrs: 1
      };

      this.isWaiting = true;
      return fetch(url, {
        method: action,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentToken
        },
        body: JSON.stringify(organizationToSave)
      })
        .then(res => {
          if (res.status === 401 || res.status === 403) {
            throw new Error("authorizationError");
          } else if (res.status !== 200 && res.status !== 201) {
            throw new Error("generalError");
          }

          return res.json();
        })
        .then(data => {
          this.closeOrganizationModal();
          this.loadOrganizations();
          this.$notify({
            title: "Excelente",
            message: "La organización se ha guardado exitosamente",
            type: "success"
          });
          setTimeout(() => {
            if (action === "POST") {
              this.$router.push("/organization/" + data.organization.id);
            }
            this.organization = organization;
          }, 1500);
        })
        .catch(error => {
          this.$notify({
            title: this.translate("thatIsBad"),
            message: this.translate("generalError"),
            type: "danger"
          });
        })
        .finally(() => {
          this.isWaiting = false;
        });
    }
  }
});
</script>
