import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "_app_organization_procede",
  tabindex: -1,
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = {
  id: "removing_organization_procede",
  class: "btn btn-sm btn-icon btn-active-color-primary",
  "data-bs-dismiss": "modal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "modal-body scroll-y mx-5 mx-xl-15 text-center" }
const _hoisted_8 = { class: "mb-9" }
const _hoisted_9 = { class: "actions text-center" }
const _hoisted_10 = {
  ref: "cancelButtonRef",
  id: "cancel_button",
  type: "reset",
  "data-bs-dismiss": "modal",
  class: "btn btn-light me-3"
}
const _hoisted_11 = { class: "indicator-label" }
const _hoisted_12 = { class: "indicator-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode("h3", null, _toDisplayString(_ctx.translate("warning")), 1),
          _createVNode("div", _hoisted_5, [
            _createVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Navigation/Close.svg" })
            ])
          ])
        ]),
        _createVNode("div", _hoisted_7, [
          _createVNode("h5", _hoisted_8, _toDisplayString(_ctx.translate("continue")), 1),
          _createVNode("div", _hoisted_9, [
            _createVNode("button", _hoisted_10, [
              _createVNode("span", _hoisted_11, _toDisplayString(_ctx.translate("cancel")), 1)
            ], 512),
            _createVNode("button", {
              ref: "submitButtonRef",
              type: "submit",
              id: "kt_modal_new_card_submit",
              class: "btn btn-primary",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirmRemoving && _ctx.confirmRemoving(...args)))
            }, [
              _createVNode("span", _hoisted_12, _toDisplayString(_ctx.translate("confirm")), 1)
            ], 512)
          ])
        ])
      ])
    ])
  ]))
}