
import { defineComponent, ref, computed, PropType, watch } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import ShowConfirmation from "./showConfirmation.vue";
import { userHierarchyLevels, departmentAreas } from "./types";

export default defineComponent({

  components: {
    ShowConfirmation,
  },

  props: {
    organization: {
      type: Object as PropType<{
        id: number;
        status: string | null;
        user_department: string | null;
        user_hierarchy_level: string | null;
        user_organization_id: number;
      }>,
      default: () => ({
        user_department: null,
        user_hierarchy_level: null,
        user_organization_id: 0
      })
    },
    updateUsers: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const editMode = ref(!props.organization.user_department && !props.organization.user_hierarchy_level);
    const store = useStore();
    const { t } = useI18n();

    const currentOrganizationId = ref(0);

    const isLoadingOrganizations = computed(
      () => store.getters.getLoadingOrganizations
    );

    // Almacenar los valores originales
    const originalDepartment = ref(props.organization.user_department || "");
    const originalHierarchyLevel = ref(
      props.organization.user_hierarchy_level || ""
    );

    // Valores seleccionados que se pueden modificar
    const selectedDepartment = ref(props.organization.user_department || "");
    const status = ref(props.organization.status || "");
    const selectedHierarchyLevel = ref(
      props.organization.user_hierarchy_level || ""
    );

    // Observar cambios en las props y actualizar los valores originales
    watch(
      () => props.organization.user_department,
      newVal => {
        originalDepartment.value = newVal || "";
      }
    );

    watch(
      () => props.organization.user_hierarchy_level,
      newVal => {
        originalHierarchyLevel.value = newVal || "";
      }
    );

    const toggleEditMode = () => {
      editMode.value = !editMode.value;
    };

    const cancelEdit = () => {
      selectedDepartment.value = originalDepartment.value;
      selectedHierarchyLevel.value = originalHierarchyLevel.value;
      toggleEditMode();
    };

    const saveChanges = async () => {
      editMode.value = false;

      try {

        if (status.value === 'pending') {
          await store.dispatch(Actions.ACCEPT_ORGANIZATION_INVITATION, {
            organizationId: props.organization.id,
            choose: 'accepted'
          });
        }

        await store.dispatch(Actions.UPDATE_MEMBER, {
          area: selectedDepartment.value,
          hierarchy: selectedHierarchyLevel.value,
          user_organization_id: props.organization.user_organization_id,
          update: props.updateUsers
        });

      } catch (error) {
        console.error("Hubo un error al actualizar el miembro", error);
      }
    };

    const userDepartment = computed(() => props.organization.user_department);

    const userHierarchyLevel = computed(
      () => props.organization.user_hierarchy_level
    );

    const isWarningState = computed(() => {
      return (
        !props.organization.user_department ||
        props.organization.user_department === "" ||
        !props.organization.user_hierarchy_level ||
        props.organization.user_hierarchy_level === ""
      );
    });

    const openModal = async (organizationId) => {
      await store.dispatch(Actions.ORGANIZATIONS_REJECT, organizationId);
    };

    const areas = ref([...departmentAreas]);
    const hierarchyLevels = ref([...userHierarchyLevels]);

    return {
      editMode,
      areas,
      hierarchyLevels,
      selectedDepartment,
      selectedHierarchyLevel,
      toggleEditMode,
      saveChanges,
      cancelEdit,
      userDepartment,
      userHierarchyLevel,
      isWarningState,
      isLoadingOrganizations,
      status,
      openModal,
      currentOrganizationId
    };
  }
});
