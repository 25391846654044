<template>
  <!-- Box -->
  <div class="msjWithoutOrganization" v-if="organizations.length === 0">
    <p>{{ translate("withOutOrganization") }}</p>
    <button
      type="button"
      class="btn btn-sm btn-success ms-4"
      data-bs-toggle="modal"
      data-bs-target="#_app_organization_new_form_modal"
      id="kt_toolbar_primary_button"
      ref="bnt1"
    >
      <i class="bi bi-plus" />
      {{ translate("createOrganization") }}
    </button>
  </div>

  <div class="row" style="margin-top: 25px">
    <div
      v-for="organization in acceptedOrganizations"
      :key="organization.id"
      class="col-xl-4"
    >
      <div>
        <OrganizationListItem :organization="organization" />
      </div>
    </div>
  </div>

  <h2
    style="color: #fff;margin-bottom: 20px;margin-top: 35px;"
    v-if="pendingOrganizations.length > 0"
  >
    {{ translate("InvitationPendingOrganizations") }}
  </h2>

  <div class="row">
    <div
      v-for="organization in pendingOrganizations"
      :key="organization.id"
      class="col-xl-4"
    >
      <div>
        <OrganizationListItem :organization="organization" />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import OrganizationListItem from "@/components/app/Organizations/OrganizationListItem.vue";
import { Actions } from "@/store/enums/StoreEnums";

export default {
  components: {
    OrganizationListItem
  },
  props: {
    organizations: {
      type: Array,
      default: () => {
        [];
      }
    }
  },

  mounted() {
    /* if (this.organizations.length === 1) {
      this.$store.dispatch(Actions.LOAD_ORGANIZATION, {
        organization: this.organizations[0],
        id: null
      });
      this.$router.push("/organization/" + this.organizations[0].id);
    } */
    setTimeout(() => {
      const btn1 = this.$refs.bnt1;
      if (btn1) btn1.click();
    }, 2500);
  },

  setup() {
    const { t, te } = useI18n();

    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate
    };
  },

  computed: {
    authenticatedUser() {
      return this.$store.getters.currentUser;
    },

    acceptedOrganizations: function() {
      return this.organizations && this.organizations.length
        ? this.organizations.filter(i => i.status === "accepted")
        : [];
    },

    pendingOrganizations: function() {
      return this.organizations && this.organizations.length
        ? this.organizations.filter(i => i.status === "pending")
        : [];
    }
  }
};
</script>

<style>
.msjWithoutOrganization {
  display: table;
  max-width: 800px;
  background: #fff;
  margin: 25px auto;
  padding: 35px;
  text-align: center;
  border-radius: 10px;
}

.msjWithoutOrganization p {
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin: 15px 0 30px 0;
}
</style>
