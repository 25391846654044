<template>
  <!--begin::Form-->
  <Form id="kt_organization_new_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate"
    @submit="save" :validation-schema="submitOrganization">
    <!--begin:: Name-->
    <div class="row mb-10">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col-->
          <div class="col-12">
            <!--begin::Label-->
            <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span class="required">{{ translate("organization") }}</span>
              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                title="Specify a card holder's name"></i>
            </label>
            <!--end::Label-->

            <Field :disabled="!isEditionEnabled" v-model="formNew.name" autofocus type="text"
              class="form-control form-control-solid" placeholder="" name="nameOnCard" id="organization-name" />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="nameOnCard" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>

    <!--begin::Input organizationsType-->
    <div class="row mb-10">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col-->
          <div class="col-12">
            <!--begin::Label-->
            <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span class="required">{{ translate("organizationsType.name") }}</span>
              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                title="Specify a card holder's name"></i>
            </label>
            <!--end::Label-->

            <!-- Industry select -->
            <Field :disabled="!isEditionEnabled" v-model="formNew.organizationsType" id="organizations_Type"
              name="organizationsType" class="form-select form-select-solid" data-control="select2"
              data-hide-search="true" data-placeholder="Month" as="select">
              <option></option>
              <template v-for="orgT in organizationsType" :key="orgT">
                <option :value="orgT">
                  {{ translate(`organizationsType.${orgT}`) }}
                </option>
              </template>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="organizationsType" />
              </div>
            </div>
            <!-- end:: Industry select -->
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>

    <!--begin:: Industry-->
    <div class="row mb-10">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col-->
          <div class="col-12">
            <!--begin::Label-->
            <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span class="required">{{ translate("industry") }}</span>
              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                title="Specify a card holder's name"></i>
            </label>
            <!--end::Label-->

            <!-- Industry select -->
            <Field :disabled="!isEditionEnabled" v-model="formNew.industry" id="industry_selector" name="industry"
              class="form-select form-select-solid" data-control="select2" data-hide-search="true"
              data-placeholder="Month" as="select">
              <option></option>

              <template v-for="(industry, industryIndex) in industries" :key="industryIndex">
                <option :value="industry.key">
                  {{ translate(`industria.${industry.key}`) }}
                </option>
              </template>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="industry" />
              </div>
            </div>
            <!-- end:: Industry select -->
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end:: Industry-->

    <!--begin::Input employees & country -->
    <div class="row mb-10">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col-->
          <div class="col-6">
            <!--begin::Label-->
            <label class="required fs-6 fw-bold form-label mb-2">{{
      translate("employees")
    }}</label>
            <!--end::Label-->

            <!-- @todo -->
            <Field :disabled="!isEditionEnabled" v-model="formNew.employees" name="employees"
              class="form-select form-select-solid" data-control="select2" data-hide-search="true"
              data-placeholder="Year" as="select" id="empleados">
              <option></option>

              <template v-for="(option, optionIndex) in quantityOptions" :key="optionIndex">
                <option :value="option.value">{{ option.show }}</option>
              </template>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="employees" />
              </div>
            </div>
          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-6">
            <!--begin::Label-->
            <label class="required fs-6 fw-bold form-label mb-2">{{
      translate("country")
    }}</label>
            <!--end::Label-->

            <!-- @todo: Country select -->
            <Field :disabled="!isEditionEnabled" v-model="formNew.country" name="country"
              class="form-select form-select-solid" data-control="select2" data-hide-search="true"
              data-placeholder="Month" as="select" id="country">
              <option></option>

              <template v-for="(country, countryIndex) in countries" :key="countryIndex">
                <option :value="country.name">{{ country.name }}</option>
              </template>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="country" />
              </div>
            </div>
            <!-- end:: Country select -->
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>

    <div class="my-12 text-center">
      <div class="text-gray-400 fw-bold fs-5">
        {{ translate("yourInformation") }}
      </div>
    </div>

    <!--begin::Input group area & hierarchy-->
    <div class="row mb-10">
      <!--begin::Col-->
      <div class="col-md-12 fv-row">
        <!--begin::Row-->
        <div class="row fv-row">
          <!--begin::Col-->
          <div class="col-6">
            <!--begin::Label-->
            <label class="required fs-6 fw-bold form-label mb-2">{{
      translate("area")
    }}</label>
            <!--end::Label-->

            <!-- @todo -->
            <Field :disabled="!isEditionEnabled" v-model="formNew.area" name="area"
              class="form-select form-select-solid" data-control="select2" data-hide-search="true"
              data-placeholder="Year" as="select" id="area">
              <option></option>

              <template v-for="(areas, optionIndex) in departmentAreas" :key="optionIndex">
                <option :value="areas">{{
      translate(`userDepartment.${areas}`)
    }}</option>
              </template>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="area" />
              </div>
            </div>
          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-6">
            <!--begin::Label-->
            <label class="required fs-6 fw-bold form-label mb-2">{{
      translate("hierarchy")
    }}</label>
            <!--end::Label-->

            <!-- @todo: Country select -->
            <Field :disabled="!isEditionEnabled" v-model="formNew.hierarchy" name="hierarchy"
              class="form-select form-select-solid" data-control="select2" data-hide-search="true"
              data-placeholder="Month" as="select" id="hierarchy">
              <option></option>

              <template v-for="(hierarchy, countryIndex) in userHierarchyLevels" :key="countryIndex">
                <option :value="hierarchy">{{
      translate(`userHierarchyLevel.${hierarchy}`)
    }}</option>
              </template>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="hierarchy" />
              </div>
            </div>
            <!-- end:: Country select -->
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>

    <!--begin::Input group-->
    <div class="d-flex flex-column mb-10">
      <label class="fs-6 fw-bold mb-2">{{
      translate("organizationReason")
    }}</label>

      <textarea id="motivo" :disabled="!isEditionEnabled" v-model="formNew.motive"
        class="form-control form-control-solid" placeholder="" name="motive" />
    </div>

    <div v-if="isCancelButtonVisible && isEditionEnabled">
      <!-- begin:: Checkbox 2 -->
      <div class="form-check text-left" style="text-align: left !important">
        <input class="form-check-input text-left" type="checkbox" value="" id="flexCheckChecked" checked />
        <label class="form-check-label" for="flexCheckChecked">
          {{ translate("organizationShareStatistic") }}
        </label>
      </div>
      <!-- end:: Checkbox 2 -->
    </div>

    <!--begin::Actions-->
    <div class="text-center mt-15">
      <button v-if="isCancelButtonVisible && isEditionEnabled" ref="cancelButtonRef" id="cancel_button" type="reset"
        class="btn btn-light me-3" @click.prevent="stopEdition">
        <span class="indicator-label">
          {{ translate("cancel") }}
        </span>
      </button>

      <button v-if="isEditionEnabled" ref="submitButtonRef" type="submit" id="kt_modal_new_card_submit"
        class="btn btn-primary save-org">
        <span class="indicator-label">
          {{ translate("save") }}
        </span>
        <span class="indicator-progress">
          {{ translate("pleaseWait") }}
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </button>
    </div>
  </Form>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { ErrorMessage, Field, Form } from "vee-validate";
import {
  userHierarchyLevels,
  departmentAreas,
  industries,
  countries,
  quantityOptions,
  organizationsType
} from "./types";
import * as Yup from "yup";

export default defineComponent({
  name: "_app_organization_form",
  components: {
    ErrorMessage,
    Field,
    Form
  },

  props: {
    isEditionEnabled: {
      type: Boolean,
      default: () => false
    },
    isCancelButtonVisible: {
      type: Boolean,
      default: () => true
    }
  },

  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    const submitOrganization = Yup.object().shape({
      nameOnCard: Yup.string().required(
        translate("organization") + " " + translate("isRequired")
      ),
      industry: Yup.string().required(
        translate("industry") + " " + translate("isRequired")
      ),
      employees: Yup.string().required(
        translate("employees") + " " + translate("isRequired")
      ),
      country: Yup.string().required(
        translate("country") + " " + translate("isRequired")
      ),
      area: Yup.string().required(
        translate("area") + " " + translate("isRequired")
      ),
      hierarchy: Yup.string().required(
        translate("hierarchy") + " " + translate("isRequired")
      ),
      organizationsType: Yup.string().required(
        translate("organizationsType.name") + " " + translate("isRequired")
      )
    });

    return {
      translate,
      submitOrganization,
      industries,
      countries,
      quantityOptions,
      userHierarchyLevels,
      departmentAreas,
      organizationsType
    };
  },

  data() {
    return {
      formNew: {
        id: null,
        name: "",
        industry: "",
        employees: "",
        visibleMembers: 0,
        country: "",
        hierarchy: "",
        area: "",
        motive: "",
        organizationsType: "",
        shareOkrs: false,
        createdAt: new Date()
      }
    };
  },

  methods: {
    stopEdition() {
      this.$emit("onStopEdition");
    },
    save() {
      // $(".save-org").attr('disabled','disabled');
      document.querySelector(".save-org").setAttribute("disabled", "disabled");
      this.$emit("onSave", this.formNew);
      console.log(this.formNew);
      setTimeout(() => {
        const modalElement = document.getElementById(
          "_app_organization_new_form_modal"
        );

        if (modalElement) {
          modalElement.click();
          // $(".save-org").removeAttr('disabled');
          document.querySelector(".save-org").removeAttribute("disabled");
        }
      }, 1000);
    }
  }
});
</script>

<style>
#kt_modal_new_card_submit:disabled,
#kt_modal_new_card_submit[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc !important;
  color: #666666;
}
</style>
