<template>
  <!--begin::Card-->
  <div class="card border border-2 border-gray-300 border-hover mb-6 p-5">
    <a href="#" @click.prevent="setOrganization">
      <!--begin:: Card body-->
      <div class="card-body p-5 pb-3">
        <!--begin::Name-->
        <div class="fs-3 fw-bolder text-dark text-center">
          {{ organization.name }}
        </div>
        <p class="text-gray-400 fw-bold fs-5 mt-1 mb-0 text-center text-limited">
          {{ translate(`industria.${organization.industry}`) }}
        </p>
        <!--end::Name-->
      </div>
    </a>
    <span v-if="organization.status == 'pending'" style="
          line-height: 1.2;
          color: #000;
          display: block;
          margin-bottom: 15px;
          font-size: 16px;
        ">
      {{ translate("youHaveARequestToJoinThisOrganization") }}
    </span>
    <DepartmentHierarchy :organization="organization" :updateUsers="false" />
    <div v-if="organization.status === 'accepted'" class="mx-12 mb-6">
      <div class="d-flex justify-content-around">
        <div class="text-center mt-4">
          <span :class="`text-${progressColor}`">
            <small class="me-1">{{ translate("absolute") }}:</small>
            <b>{{ parseInt(organization.progress.absolute) }}</b> %</span>
        </div>

        <div class="text-center mt-4">
          <span :class="`text-${progressColor}`">
            <small class="me-1">{{ translate("relative") }}:</small>
            <b>{{ parseInt(organization.progress.relative) }}</b> %</span>
        </div>
      </div>
    </div>
  </div>
  <!--end::Card-->
</template>

<script>
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import ProgressBar from "@/components/app/ui/ProgressBar.vue";
import DepartmentHierarchy from "./DepartmentHierarchy.vue";
/* eslint-disable */
export default {
  components: {
    ProgressBar,
    DepartmentHierarchy
  },
  props: {
    organization: {
      type: Object,
      default: () => { },
    },
  },
  setup(props) {
    const { t, te } = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    return {
      translate,
    };
  },

  methods: {

    setOrganization() {
      if (this.organization.user_department != null && this.organization.user_hierarchy_level != null) {
        this.$store.dispatch(Actions.LOADED_OBJECTIVE, false);
        this.$store.dispatch(Actions.LOAD_ORGANIZATION, {
          organization: this.organization,
          id: null,
        });
        this.$router.push("/organization/" + this.organization.id);
      } else {
        this.$notify({
          title: this.translate("bad"),
          message: this.translate("need-department-hierarchy"),
          type: "error",
        });
      }
    },

    startOrganizationEdition(organization) {
      this.emitter.emit("open-organization-modal", organization);
    },

    startOrganizationRemoving(organization) {
      this.emitter.emit("open-organization-removing-modal");
    },

    translate(text) {
      const { t, te } = useI18n();
      return te(text) ? t(text) : text;
    },
  },

  computed: {
    progressColor() {
      if (!this.organization || !this.organization.progress) {
        return "text-primary";
      }

      if (parseInt(this.organization.progress) <= 33) {
        return "text-danger";
      } else if (
        parseInt(this.organization.progress) > 33 &&
        parseInt(this.organization.progress) <= 66
      ) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
  },
};
</script>

<style scoped>
.symbol {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.text-limited {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-height: 3rem;
  min-height: 3rem;
}

.col-xl-4 {
  margin-bottom: 15px !important;
}
</style>
