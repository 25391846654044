<template>
  <div :class="`bg-${color}`" class="progress h-7px bg-opacity-50">
    <div
      :class="`bg-${color}`"
      class="progress-bar"
      role="progressbar"
      :style="`width: ${progress}%`"
      aria-valuenow="50"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: () => "primary"
    },
    progress: {
      type: String,
      default: () => "0"
    }
  }
};
</script>

<style>
.progress {
  min-width: 190px !important;
}
</style>
